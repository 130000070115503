
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import state, { signIn, signOut } from "@/store/driveStore";
import { mdiLogout, mdiAccount } from "@mdi/js";

/**
 * Login/Logout of Google
 */
@Component({
  name: "NavBarLogin",
  components: {},
})
export default class Login extends Vue {
  private mdiLogout = mdiLogout;
  private mdiAccount = mdiAccount;

  private get loggedOut() {
    return state.user === null;
  }

  private get userFirstLetter() {
    if (state.user) {
      return state.user.getBasicProfile().getName().slice(0, 1);
    }
    return null;
  }

  private mounted() {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.onload = () => {
      signIn("google-signin-button");
    };
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  private signOut() {
    signOut();
  }
}
