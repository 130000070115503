import {
  ColDef,
  ColGroupDef,
  GridApi,
  ValueGetterParams,
} from "@ag-grid-community/core";
import AgPreview from "./AgPreview.vue";
import IconCellRenderer from '@/components/TableAndMap/Table/Logic/IconCellRenderer.vue'
import { max } from "@/logic/Math";
import UploadedFile, { Row, ColumnSelections } from "@/entities/UploadedFile";
import Vue from 'vue'
import Vuex from 'vuex'
import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { EventBus } from '@/setups/event-bus';
import CenteredCellRenderer from "@/components/TableAndMap/Table/Logic/CenteredCellRenderer.vue";
import db from '@/setups/Firebase';
import StatusFilter from '@/components/TableAndMap/Table/Logic/StatusFilter.vue';


export default class TableLogic {
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public api: GridApi | null = null;
  public rowData: any[] = [];
  public gridOptions: { [key: string]: any } = { 
    domLayout: 'autoHeight', 
    enableFilter: true
  };

  public isComponentAlive: boolean = true; 
  public unsubscribeFromPriorityUpdates: Function | null = null;
  public defaultPriority: number | null = null;




  public setGridApi(api: GridApi) {
	  this.api = api;
  }
	

  public onComponentDestroyed() {
	  this.isComponentAlive = false;
	  if (this.unsubscribeFromPriorityUpdates) {
		this.unsubscribeFromPriorityUpdates();
	  }
  }

  





  public setDataWithDelay(uploadedFile: UploadedFile, delay = 2000) {
    setTimeout(() => this.setData(uploadedFile), delay);
  }


  public updateRowData(newData: any[]) {
    this.rowData = newData;
    this.refreshGrid();
  }

  public refreshGrid() {
	  if (this.api) {
		this.api.refreshCells();
	  }
  }






  private computeColumnTypes(uploadedFile: UploadedFile) {
    const columnTypes: { number: number; string: number }[] = [];
    const maxCols = max(uploadedFile.data.map((_) => _.data.length));
    for (let i = 0; i < maxCols; i++) {
      columnTypes.push({ number: 0, string: 0 });
    }
    uploadedFile.data
      .slice(uploadedFile.firstRowHeader ? 1 : 0)
      .forEach((row) => {
        Object.entries(row.data).forEach(([key, value], colIndex) => {
          if (isNaN(value)) {
            columnTypes[colIndex].string += 1;
          } else {
            columnTypes[colIndex].number += 1;
          }
        });
      });
    return columnTypes.map((meta) => {
      return (meta.number / (meta.number + meta.string)) > 0.95
        ? "agNumberColumnFilter"
        : "agTextColumnFilter";
    });
  }

  public setData(uploadedFile: UploadedFile) {
    EventBus.$on('priority-updated', (priority: number) => {
		this.refreshGrid();
	});
    const columnTypes = this.computeColumnTypes(uploadedFile);
    const latitudeColumnIndex = (uploadedFile.data[0].columnSelections as UploadedFile['columnSelections'])?.lat ?? null;
    const longitudeColumnIndex = (uploadedFile.data[0].columnSelections as UploadedFile['columnSelections'])?.lng ?? null;

    let headers: string[] = [];
    if (uploadedFile.firstRowHeader) {
        headers = uploadedFile.headers;
    } else {
        headers = Object.keys(uploadedFile.data[0]); // Use keys of the first object as headers
    }

    // Mapping of original header to new header
    const headerMapping: { [key: string]: string | undefined } = {
        "index": "JobNo",
        "data": "Description",
        "columnSelections": "Address",
        "features": "Client"
    };


    // Replace original headers with new ones
    headers = headers.map(header => headerMapping[header] || header);

    const generatedCols: (ColDef | ColGroupDef)[] = headers
      .map((headerName: string, index: number) => {
        if (index === latitudeColumnIndex || index === longitudeColumnIndex) {
          return null;
        }

        const adjustedHeaderName = headerName === "Priority:" ? "Target (Days)" : headerName;

        // Check if it's the "JobNo" column
        if (adjustedHeaderName === "JobNo") {
          return {
            headerName: adjustedHeaderName,
            field: "jobNumber", // Assuming the field name is "jobNumber"
            filter: columnTypes[index],
            sortable: true,
            resizable: true,	
            width: 100,
			flex: 0.35,// Set the desired width for the "JobNo" column
            valueGetter: (params: ValueGetterParams) => {
              return params.data.data ? params.data.data[index] : null;
            },
          };
        }

        // Check if it's the "JobNo" column
        if (adjustedHeaderName === "Description") {
          return {
            headerName: adjustedHeaderName,
            field: "jobNumber", // Assuming the field name is "jobNumber"
            filter: columnTypes[index],
            sortable: true,
            resizable: true,
            width: 200,
			flex: 1.6,// Set the desired width for the "JobNo" column
            valueGetter: (params: ValueGetterParams) => {
              return params.data.data ? params.data.data[index] : null;
            },
          };
        }

		// Check if it's the "Address" column
		if (adjustedHeaderName === "Address") {
		  return {
			headerName: adjustedHeaderName,
			filter: columnTypes[index],
			sortable: true,
			resizable: true,
			flex: 1.5, // Adjust the flex value here
			valueGetter: (params: ValueGetterParams) => {
			  return params.data.data ? params.data.data[index] : null;
			},
		  };
		}

		// Check if it's the "Client" column
		if (adjustedHeaderName === "Client") {
		  return {
			headerName: adjustedHeaderName,
			filter: columnTypes[index],
			sortable: true,
			resizable: true,
			flex: 1.2, // Adjust the flex value here
			valueGetter: (params: ValueGetterParams) => {
			  return params.data.data ? params.data.data[index] : null;
			},
		  };
		}

        return {
          headerName: adjustedHeaderName,
          filter: columnTypes[index],
          sortable: true,
          resizable: true,
          valueGetter: (params: ValueGetterParams) => {
            return params.data.data ? params.data.data[index] : null;
          },
        };
      })
      .filter((col: ColDef | ColGroupDef | null) => col !== null) as (ColDef | ColGroupDef)[];


    const startDateCol: ColDef = {
        headerName: "Start Date",
        filter: "agDateColumnFilter",
        width: 100,
		flex: 0.4,
        sortable: true,
        resizable: true,

        valueGetter: (params: ValueGetterParams) => {
            const row = params.data as Row; // Cast data as Row
            let startDate;
            const startDateIndex = uploadedFile.startDateIndex; // get the start date index here, you need to define this in your UploadedFile class

            if (row.data && Array.isArray(row.data)) {
                if (startDateIndex !== null) {
                    const [day, month, year] = row.data[startDateIndex].split("/");
                    startDate = new Date(`${month}/${day}/${year}`);  // Use startDateIndex to access start date value
                }
            } else {
                console.log("Missing data")
            }

            if (!startDate) {
                console.log('Missing or invalid data', row.data, 'startDate:', startDate);
                return null;
            }

            return startDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
        },
    };


	// Fetch default priority from Firestore
	db.collection('Priority').doc('Priority').get()
		.then((doc) => {
			if (doc.exists) {
				const data = doc.data();
				if (data) {
					this.defaultPriority = data.defaultPriority;
				}
			} else {
				console.log('No such document!');
			}
		})
		.catch((error) => {
			console.log('Error getting document:', error);
		})
		.finally(() => {
			if (this.api) {
				// Refresh the data grid once defaultPriority is fetched
				this.api.refreshCells({force: true});

				// Apply sorting
				this.api.setSortModel([{colId: 'daysToTarget', sort: 'desc'}]);  // Please replace 'daysToTarget' with your field name
			}
		});



	const priorityCol: ColDef = {
		headerName: "Priority (Days)",
		filter: "agNumberColumnFilter",
		width: 50,
		flex: 0.2,
		sortable: true,
		resizable: true,
		valueGetter: (params: ValueGetterParams) => {
			const row = params.data as Row; // Cast data as Row
			let priority;
			const priorityIndex = uploadedFile.priorityIndex; // get the priority index here

			if (row.data && Array.isArray(row.data)) {
				if (priorityIndex !== null) {
					priority = row.data[priorityIndex];  // Use priorityIndex to access priority value
				}
			} else {
				console.log("Missing data")
			}

			if (!priority || isNaN(priority)) {
				console.log('Missing or invalid data', row.data, 'priority:', priority);
				return null;
			}

			// Check if priority is 4586 and replace it with defaultPriority if necessary
			if (priority == 4586 && this.defaultPriority !== null) {
				priority = this.defaultPriority;
			}

			
			return priority;
		},
	};

    // Add target date column
    const targetDateCol: ColDef = {
        headerName: "Target Date",
        filter: "agDateColumnFilter",
        width: 120,
		flex: 0.4,
        sortable: true,
        resizable: true,
        valueGetter: (params: ValueGetterParams) => {
            const row = params.data as Row; // Cast data as Row
            let priority;
            const priorityIndex = uploadedFile.priorityIndex; // get the priority index here
            const startDateIndex = uploadedFile.startDateIndex; // get the start date index here, you need to define this in your UploadedFile class
            let startDate;

            if (row.data && Array.isArray(row.data)) {
                if (priorityIndex !== null) {
                    priority = row.data[priorityIndex];  // Use priorityIndex to access priority value
                }

                if (startDateIndex !== null) {
                    const [day, month, year] = row.data[startDateIndex].split("/");
                    startDate = new Date(`${month}/${day}/${year}`);  // Use startDateIndex to access start date value
                }
            } else {
                console.log("Missing data")
            }

            if (!priority || isNaN(priority) || !startDate) {
                console.log('Missing or invalid data', row.data, 'startDate:', startDate, 'priority:', priority);
                return null;
            }

            startDate.setDate(startDate.getDate() + Number(priority)- 1);
            return startDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
        },
    };

    const daysToTargetCol: ColDef = {
        headerName: "Days To Target",
        filter: "agNumberColumnFilter",
		field: "daysToTarget",
        width: 140,
		flex: 0.5,
        sortable: true,
        resizable: true,
        sort: 'desc', // sort descending by default
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (valueA < 0 && valueB >= 0) {
            return 1; // Lapsed jobs first
          }
          if (valueA >= 0 && valueB < 0) {
            return -1; // Non-lapsed jobs after
          }
          if (valueA < 0 && valueB < 0) {
            return valueB - valueA; // Among lapsed jobs, most lapsed first
          }
          // Among non-lapsed jobs, closest to being lapsed first
          return valueB - valueA;
        },
        valueGetter: (params: ValueGetterParams) => {
            const row = params.data as Row; // Cast data as Row
            let priority;
            const priorityIndex = uploadedFile.priorityIndex; // get the priority index here
            const startDateIndex = uploadedFile.startDateIndex; // get the start date index here, you need to define this in your UploadedFile class
            let startDate;

            if (row.data && Array.isArray(row.data)) {
                if (priorityIndex !== null) {
                    priority = row.data[priorityIndex];  // Use priorityIndex to access priority value
                }

                if (startDateIndex !== null) {
                    const [day, month, year] = row.data[startDateIndex].split("/");
                    startDate = new Date(`${month}/${day}/${year}`);  // Use startDateIndex to access start date value
                }
            } else {
                console.log("Missing data")
            }

            if (!priority || isNaN(priority) || !startDate) {
                console.log('Missing or invalid data', row.data, 'startDate:', startDate, 'priority:', priority);
                return null;
            }

            startDate.setDate(startDate.getDate() + Number(priority) - 1); // Calculate target date
            const targetDate = startDate;

            // Get difference between target date and current date in days
            const currentDate = new Date();
            const diffTime = targetDate.getTime() - currentDate.getTime();
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1; // Convert diffTime to days

            return diffDays;
        },
        cellStyle: (params: any) => {
            if (params.value < 0) {
                return {
                    color: 'red',
                    backgroundColor: '#ffe6e6', // light red background
                    fontWeight: 'bold', // bold text
                };
            }
            else if (params.value <= 2) {
                return {
                    color: '#ff8503',
                    backgroundColor: '#ffecb3', // light amber background
                    fontWeight: 'bold', // bold text
                };
            }
            return {
                color: 'green',
                backgroundColor: '#e6ffe6', // light green background
                fontWeight: 'bold', // bold text
            };
        },
    };


	const iconCol: ColDef = {
		headerName: "Status",
		field: 'status',
		pinned: "right",
		cellRendererFramework: IconCellRenderer,
		filterFramework: StatusFilter, // use custom filter
		filterParams: {
			parentComponent: this,
		},
		suppressMenu: false, // Changed to false to show the menu icon
		sortable: false,
		resizable: false,
		flex: 1,
		minWidth: 100, // Minimum width of the column
	    maxWidth: 180,
	};






    const previewCol: ColDef = {
        headerName: "",
        field: "preview",
        pinned: "left",
        width: 45,
        suppressMenu: true,
        sortable: true,
		flex: 1,
        cellRenderer: undefined,
        cellRendererFramework: AgPreview,
		cellStyle: { 'text-align': 'center' }
    };

    this.columnDefs = [previewCol].concat(generatedCols).concat(startDateCol).concat(priorityCol).concat(targetDateCol).concat(daysToTargetCol).concat(iconCol);
  }
}

