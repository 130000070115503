
	import { Component, Vue, Prop } from "vue-property-decorator";
	import state, { updateViewOptions, updateOverlayEventJsons, updateFilters, exportToCsv } from "@/store/exploreStore";
	import { mdiExport, mdiTable, mdiMap, mdiLayers, mdiSecurity, mdiXml, mdiFilterRemoveOutline, mdiMicrosoftExcel, mdiArrowRight } from "@mdi/js";
	import LayerManager from "./LayerManager/LayerManager.vue";
	import EmbedCode from "./EmbedCode/EmbedCode.vue";
	import driveState from "@/store/driveStore";
	import OnUploadUpsell from "@/components/Pricing/Upsell/OnUpload/OnUpload.vue";
	import NorwichElec from "@/assets/NorwichElec.png";
	import GoogleMapLogic from '@/components/TableAndMap/GoogleMap/Logic/GoogleMapLogic';
	import db from "@/setups/Firebase";
	import { EventBus } from '@/setups/event-bus';
	import UpdateJobsLogic from './UpdateJobsLogic'
	import firebase from "firebase/compat/app";



	/**
	* Table options
	*/
	@Component({
	name: "NavBarNavigationDrawer",
	components: {
	LayerManager,
	EmbedCode,
	OnUploadUpsell,
	},
	})
	export default class NavigationDrawer extends Vue {
	private activeText: string = "Hide ";
	private inactiveText: string = "Show ";
	private mdiExport = mdiExport;
	private mdiLayers = mdiLayers;
	private mdiSecurity = mdiSecurity;
	private mdiXml = mdiXml;
	private mdiMicrosoftExcel = mdiMicrosoftExcel;
	private mdiFilterRemoveOutline = mdiFilterRemoveOutline;
	private mdiArrowRight = mdiArrowRight;
	private uploadLayerModal: boolean = false;
	private copyLinkDisplay = false;
	private displayEmbedCode = false;
	private onUploadUpsell = false;
	private headline = '';
	private defaultPriority: string = "";
	private unsubscribe: any = null;
	private pageRefreshNeeded: boolean = false;
	private showDialog: boolean = false;
	private file: File | null = null;
	private progress: number = 0;
	private problematicJobs: string[] = [];
	private jobsAreValid: boolean = false;
	private showSiteSettings: boolean = false;
	private isAdmin: boolean = false;



	private getCurrentDateTime(): string {
	const currentDate = new Date();
	const dateString = currentDate.toLocaleDateString('en-US', {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit'
	});
	return dateString;
	}

	validateJobs() {
	console.log('validateJobs called');
	if (this.file) {
	let updateJobsLogic = new UpdateJobsLogic();
	this.progress = 0;  // reset progress bar
	this.jobsAreValid = false;  // reset jobsAreValid flag
	this.problematicJobs = [];  // reset problematicJobs array
	updateJobsLogic.processFile(this.file, (progress) => {
	this.progress = progress;
	}, false).then((problematicJobs) => {
	console.log('problematicJobs in validateJobs:', problematicJobs);
	this.problematicJobs = problematicJobs;
	if (this.problematicJobs.length === 0) {
	this.jobsAreValid = true;
	}
	});
	this.showDialog = true;
	}
	}


	updateJobs() {
	console.log('updateJobs called');

	// Log a message if there are problematic jobs but still continue
	if (this.problematicJobs.length > 0) {
	console.log(`Uploading with ${this.problematicJobs.length} problematic jobs.`);
	}

	if (this.file) {
	let updateJobsLogic = new UpdateJobsLogic();
	this.progress = 0;  // reset progress bar
	this.jobsAreValid = false;  // reset jobsAreValid flag
	this.problematicJobs = [];  // reset problematicJobs array
	updateJobsLogic.processFile(this.file, (progress) => {
	this.progress = progress;
	}, true);
	this.showDialog = true;
	}
	}



	private get viewOptions() {
	return state.viewOptions;
	}

	private get isPublic() {
	const file = driveState.files.find(
	(file) => file.name === state.uploadedFile!.fileName
	)!;
	return file ? file.shared : false;
	}

	private get isSaved() {
	if (state.uploadedFile) {
	return (
	driveState.files.filter((_) => _.name === state.uploadedFile!.fileName)
	.length > 0
	);
	}
	return false;
	}

	private get groupByKeyItems() {
	return [
	{ text: 'JobNo', value: '0' },
	{ text: 'Description', value: '1' },
	{ text: 'Address', value: '2' },
	{ text: 'Client', value: '3' },
	{ text: 'Start Date', value: '4' },
	{ text: 'Priority (Days)', value: '5' },
	];
	}



	private get groupByKeyValue() {
	const value = this.viewOptions.find((_) => _.startsWith("map:groupByKey:"));
	if (value) {
	return value.split("map:groupByKey:")[1];
	}
	return null;
	}
	private set groupByKeyValue(newValue: string | null) {}

	private get unselectedMarkerOpacityValue() {
	const value = this.viewOptions.find((_) => _.startsWith("map:unselectedMarkerOpacity:"));
	if (value) {
	return parseInt(value.split("map:unselectedMarkerOpacity:")[1]);
	}
	return 0;
	}
	private set unselectedMarkerOpacityValue(newValue: number | null) {}

	private dropdowns = [
	{
	label: "Table",
	icon: mdiTable,
	dropdowns: [
	{
	label: "Table",
	key: "table",
	},
	{
	label: "Default Priority",
	key: "table:defaultPriority",
	}
	],
	},
	{
	label: "Map",
	icon: mdiMap,
	dropdowns: [
	{
	label: "Map",
	key: "map",
	},
	{
	label: "Heat Map",
	key: "map:heat",
	},
	{
	label: "Markers",
	key: "map:disableMarkers"
	},
	{
	label: "Markers",
	key: "map:markers",
	},
	{
	label: "Clusters",
	key: "map:clusters",
	},
	{
	label: "Group By",
	key: "map:groupByKey",
	},
	{
	label: "Unselected Marker Opacity",
	key: "map:unselectedMarkerOpacity",
	},
	],
	},
	];

	private openEmbedCode() {
	if (driveState.tier === 1) {
	this.displayEmbedCode = true;
	} else {
	this.headline = "Upgrade to Pro to embed on your website";
	this.onUploadUpsell = true;
	}
	}

	private async defaultPriorityChange() {
	const priorityDoc = db.collection("Priority").doc("Priority");
	const doc = await priorityDoc.get();

	if (!doc.exists) {
	// No existing document, create a new one
	await priorityDoc.set({
	defaultPriority: this.defaultPriority,
	pageRefreshNeeded: true // set the flag to indicate page refresh is needed
	});
	} else {
	// Existing document found, update it
	this.defaultPriority = doc.data().defaultPriority;
	}
	}

	async created() {
	// Start listening to the document
	const priorityDoc = db.collection("Priority").doc("Priority");
	this.unsubscribe = priorityDoc.onSnapshot((doc) => {
	if (doc.exists) {
	this.defaultPriority = doc.data().defaultPriority;
	if (doc.data().pageRefreshNeeded) {
	// refresh the page and update the flag
	location.reload();
	priorityDoc.update({
	pageRefreshNeeded: false
	});
	}
	// Here we can add the code to update component data instead of refreshing
	this.updateComponentData();
	} else {
	console.log("No such document!");
	}
	});

	// Admin check logic
	const user = firebase.auth().currentUser; // get the currently logged-in user
	if (user) {
	const userDoc = await db.collection('users').doc(user.uid).get(); // get the user's document from Firestore
	if (userDoc.exists) {
	this.isAdmin = userDoc.data().admin; // set the isAdmin property based on the admin field in Firestore
	}
	}
	}



	updateComponentData() {
	// Here we can add the code to update specific component data
	this.defaultPriority = this.defaultPriority; // example data property update

	// Assuming you have access to your UploadedFile instances in a variable named uploadedFiles
	this.uploadedFiles.forEach(uploadedFile => {
	// Update priority in each Row.data
	uploadedFile.data.forEach(row => {
	if (Array.isArray(row.data) && uploadedFile.priorityIndex !== null) {
	const priorityFromJson = row.data[uploadedFile.priorityIndex];

	// If priority from JSON data is 4586, use priority from Firestore
	if (priorityFromJson === 4586) {
	console.log(`Assigning priority from Firestore: ${this.defaultPriority}`);
	uploadedFile.columnSelections.priority = this.defaultPriority;
	row.data[uploadedFile.priorityIndex] = this.defaultPriority;
	}
	}
	});
	});
	}

	// Add beforeDestroy lifecycle hook to stop listening to changes
	beforeDestroy() {
	this.unsubscribe(); // Unsubscribe from changes
	}

	// Refresh page
	refreshPage() {
	// Here we can add the code to refresh the page or certain components
	location.reload(); // full page reload
	// or you can update specific component data based on your requirement
	}

	defaultPriorityChange() {
	const priorityDoc = db.collection("Priority").doc("Priority");
	priorityDoc.update({
	defaultPriority: this.defaultPriority
	});
	}

	private keyVisible(key: string) {
	return this.viewOptions.indexOf(key) > -1;
	}


	private updateViewOptions(selection: { label: string; key: string }) {
	let newOptions: string[] = [];
	if (this.keyVisible(selection.key)) {
	newOptions = this.viewOptions.filter((_) => _ !== selection.key);
	if (selection.key === "map:heat") {
	newOptions.push("map:markers");
	if (state.uploadedFile && state.uploadedFile.data.length > 1000) {
          newOptions.push("map:clusters");
        }
      }
      if (selection.key === "table:footer") {
        newOptions = newOptions.filter((_) => !_.startsWith("table:footer"));
      }
    } else {
      newOptions = this.viewOptions.concat(selection.key);
      if (selection.key === "map:heat") {
        newOptions = newOptions.filter(
          (_) => ["map:markers", "map:clusters"].indexOf(_) < 0
        );
      }
      if (selection.key === "table:footer") {
        newOptions.push("table:footer:avg");
      }
    }
    if (!newOptions.includes("map") && !newOptions.includes("table")) {
      if (this.viewOptions.includes("map")) {
        newOptions.push("table");
      } else {
        newOptions.push("map");
      }
    }
    updateViewOptions(newOptions);
  }

  private groupByKeyChange(value: string | undefined) {
    const newOptions: string[] = this.viewOptions.filter(
      (_) => !_.startsWith("map:groupByKey")
    );
    if (value) {
      newOptions.push(`map:groupByKey:${value}`);
    }
    if (value === '6') { // if value is 'Target Date'
        // Insert logic for 'Target Date' here
        this.gridOptions.api.setSortModel([
            {colId: 'targetDateCol', sort: 'asc'}
        ]); // sorting data based on targetDateCol
        this.gridOptions.api.refreshCells(); // Refresh grid after sorting
    } else if (value === '7') { // if value is 'Days To Target'
        // Insert logic for 'Days To Target' here
        this.gridOptions.api.setSortModel([
            {colId: 'daysToTargetCol', sort: 'asc'}
        ]); // sorting data based on daysToTargetCol
        this.gridOptions.api.refreshCells(); // Refresh grid after sorting
    }
    updateViewOptions(newOptions);
  }


  private unselectedMarkerOpacityChange(value: number | undefined) {
    const newOptions: string[] = this.viewOptions.filter(
      (_) => !_.startsWith("map:unselectedMarkerOpacity")
    );
    if (value) {
      newOptions.push(`map:unselectedMarkerOpacity:${value || 0}`);
    }
    updateViewOptions(newOptions);
  }

  private updateShared() {
    /**
     * Update the permissions of this file
     */
    this.$emit("update-shared");
  }

  private copyLink() {
    const configFile = driveState.files.find(
      (_) => _.name === `${state.uploadedFile!.fileName}.json`
    );
    if (configFile) {
      const url = `${process.env.VUE_APP_BASE_URL}/explore/${configFile.id}`;
      const input = document.createElement("input");
      input.setAttribute("value", url);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.copyLinkDisplay = true;
      setTimeout(() => {
        this.copyLinkDisplay = false;
      }, 2000);
    }
  }

  private exportToCsv() {
    if (driveState.tier === 1) {
      exportToCsv();
    } else {
      this.headline = "Upgrade to Pro to export this dataset";
      this.onUploadUpsell = true;
    }
  }

  private clearAllFilters() {
      console.log('clearAllFilters was called');
	  EventBus.$emit('clear-filters'); // Emit event
	  updateFilters({});
	  updateOverlayEventJsons([]);
  }
  private NorwichElec = NorwichElec;
  
  }
