import  db  from '@/setups/Firebase';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import * as XLSX from 'xlsx';
import axios from 'axios';

export default class UpdateJobsLogic {
  async processFile(file: File, onProgress: (progress: number) => void, upload: boolean = false) {
	  try {
		const data = await this.readExcelFile(file);
		console.log("Processing started.");
		const problematicJobs = await this.uploadDataToFirestore(data, onProgress, upload);
		console.log("Processing finished.");
		return problematicJobs;
	  } catch (error) {
		console.log(error);
	  }
  }



  readExcelFile(file: File): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event.target?.result;

        if (typeof result === 'string') {
          reject(new Error('Unexpected string in file reader result'));
        } else if (result) {
          const data = new Uint8Array(result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });

          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          resolve(jsonData);
        } else {
          reject(new Error('Failed to read the file'));
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }



  
 async uploadDataToFirestore(data: Array<any>, onProgress: (progress: number) => void, upload: boolean = false) {
  const headers = data[1];
  const records = data.slice(2);
  const totalRecords = records.length;

  const problematicJobs = [];  // array to hold problematic job numbers

  const oldJobsList: string[] = [];  // array to hold old jobs
  // array to hold old jobs

  // Fetch all jobs before processing the new file
  if (upload) {
    const jobsSnapshot = await db.collection('Jobs').get();
    jobsSnapshot.forEach(doc => {
      oldJobsList.push(doc.id);  // assuming doc.id is the Job No
    });
  }

  for (let i = 1; i < totalRecords; i++) {
	  const record = records[i];
	  const jobNo = record[0];
	  if (!jobNo) {
		continue;
	  }

	  // Remove job from oldJobsList
	  const index = oldJobsList.indexOf(jobNo);
	  if (index > -1) {
		oldJobsList.splice(index, 1);
	  }

	  let description = record[1];
	  const client = record[2];
	  let startDate = record[4];

	  let descriptionParts = [''];
	  if (description) {
		descriptionParts = description.split('-');
	  }

	  let address = '';
	  let priorityInt: number;

	  if (descriptionParts.length >= 2) {
		address = descriptionParts[0].trim();
		description = descriptionParts[1].trim();
	  }

    if (!address) {
      console.log(`Skipping record ${i} due to missing address`);
      problematicJobs.push({jobNo: jobNo, problem: 'Missing address'});
      continue;
    }

    priorityInt = 4586;

    if (descriptionParts.length >= 3) {
      const priorityTemp = parseInt(descriptionParts[2].trim());
      if(!isNaN(priorityTemp)) {
        priorityInt = priorityTemp;
      }
    }

    let latitude = null;
    let longitude = null;

    try {
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          address: address,
          region: 'uk',
          key: process.env.VUE_APP_GEOCODE_KEY,
          bounds: '52.0989459,0.5053525|52.986375,1.733413'
        }
      });

      if (response.data.results[0]) {
        latitude = response.data.results[0].geometry.location.lat;
        longitude = response.data.results[0].geometry.location.lng;

        // Check if the location is outside the new specified region
        if (latitude < 51.81865970615904 || latitude > 53.01075872354744 || longitude < 0.20832046327920367 || longitude > 1.9204817403652963) {
          console.log(`Geocoding result for address: ${address} is outside the specified region`);
          problematicJobs.push({jobNo: jobNo, problem: `Geocoding result for address: ${address} is outside East Anglia region`});
          // Removed the "continue" statement so the function keeps processing the record
        }
      } else {
        console.log(`No geocode results for address: ${address}`);
        problematicJobs.push({jobNo: jobNo, problem: `Failed to geocode address: ${address}`});
        continue;
      }
    } catch (error) {
      console.log(`Geocoding error: ${error}`);
      problematicJobs.push({jobNo: jobNo, problem: `Geocoding error: ${error}`});
      continue;
    }

    if (jobNo && description && client && startDate) {
      if (typeof startDate === 'string') {
        const dateMoment = moment(startDate, 'DD/MM/YYYY');
        
        if (dateMoment.isValid()) {
          const dateObject = dateMoment.toDate();
          startDate = firebase.firestore.Timestamp.fromDate(dateObject);
        } else {
          console.log(`Invalid date: ${startDate}`);
          problematicJobs.push({jobNo: jobNo, problem: 'Invalid start date'});
          continue;
        }
      }

      const now = new Date().toISOString();
      const jobData = {
        'Job No.': jobNo,
        'Description': description,
        'Client': client,
        'Start Date': startDate,
        'Address': address,
        'Priority': priorityInt,
        'Latitude': latitude,
        'Longitude': longitude,
        'Last Updated': now  // save ISO 8601 formatted string
      };

      try {
        if (upload) {
          await db.collection('Jobs').doc(jobNo).set(jobData);
        }
        onProgress((i + 1) / totalRecords * 100);
      } catch (error: any) {
        console.log(`Error uploading job: ${jobNo}. Error: ${error.message}`);
        problematicJobs.push({jobNo: jobNo, problem: `Error uploading job: ${error.message}`});
      }
    } else {
      console.log(`Invalid data found: ${JSON.stringify(record)}`);
      problematicJobs.push({jobNo: jobNo, problem: 'Invalid data found'});
    }
  }

  // Move remaining jobs in oldJobsList to Archive collection
  for (const jobNo of oldJobsList) {
	  const doc = await db.collection('Jobs').doc(jobNo).get();
	  if (doc.exists) {
		await db.collection('Archive').doc(jobNo).set(doc.data()!);
		await db.collection('Jobs').doc(jobNo).delete();
	  }
  }


  return problematicJobs;
 }
}
