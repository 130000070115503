import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/setups/store';
import firebase from 'firebase/compat/app';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
ModuleRegistry.register(ClientSideRowModelModule);

Vue.config.productionTip = false;

import vuetify from '@/setups/vuetify';
import "@/setups/logrocket";
import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';


Vue.use(VuetifyDialog);

let app: Vue | null = null;

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      store,
      router,
      vuetify,
      render: h => h(App),
    }).$mount('#app');
  }
});
