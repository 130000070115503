
import { Component, Vue, Watch } from "vue-property-decorator";
import { ICellRendererParams } from "@ag-grid-community/core";
import '@/assets/Icons/uicons-bold-rounded/css/uicons-bold-rounded.css';
import db from '@/setups/Firebase';
import confetti from 'canvas-confetti';

@Component({
  name: "IconCellRenderer",
  components: {},
})
export default class IconCellRenderer extends Vue {
  private params!: ICellRendererParams;
  private hasNote: boolean = false;
  private isSnoozed: boolean = false;
  private isHidden: boolean = false;
  private isEyeCrossedClicked: boolean = false;
  private isBooked: boolean = false;
  private isCompleted: boolean = false;
  private unsub: { [key: string]: Function } = {};
  
  private onClick() {
    /**
     * Open the preview card from a click in the table
     *
     * @type { id: string, validate: boolean }
     */
    this.$parent.$parent.$emit("marker-selected", { id: this.params.data.id, validate: false });
  }
  
  
  private checkIfCompleted() {
	  let jobNo = this.params.data.data[0];
	  const docRef = db.collection("Completed").doc(jobNo);

	  docRef.onSnapshot((doc) => {
		if (doc.exists) {
		  this.isCompleted = true;
		} else {
		  this.isCompleted = false;
		}
	  });
  }
  
  async toggleCompleted() {
    this.isCompleted = !this.isCompleted;
    
    let jobNo = this.params.data.data[0];
	let colorsArray = [
	  ['#ff0000', '#00ff00'],
	  ['#0000ff', '#ffff00'],
	  ['#00ffff', '#ff00ff'],
	  ['#FF6347', '#40E0D0'], 
	  ['#FA8072', '#F0E68C'], 
	  ['#ADFF2F', '#FFD700'], 
	  ['#FF69B4', '#D2B48C'], 
	  ['#8A2BE2', '#7FFF00'], 
	  ['#00BFFF', '#F08080'], 
	  ['#DC143C', '#00FFFF'], 
	  ['#00008B', '#BDB76B'], 
	  ['#008080', '#556B2F'], 
	  ['#B22222', '#FF00FF'], 
	  ['#8B0000', '#2E8B57'], 
	  ['#228B22', '#FFA500'], 
	  ['#CD5C5C', '#9ACD32'], 
	  ['#DEB887', '#FF4500'], 
	  ['#32CD32', '#FFD700'], 
	  ['#FF1493', '#ADFF2F'], 
	  ['#BA55D3', '#6A5ACD']
	];



    if (this.isCompleted) {
      // Add to Completed collection in Firestore.
	  let rect = event.target.getBoundingClientRect();
	  let x = rect.left + rect.width / 2;
	  let y = rect.top + rect.height / 2;

	  confetti({
		  particleCount: Math.floor(Math.random() * 21) + 80, // between 80 and 100
		  spread: Math.random() * 10 + 60, // between 60 and 70
		  angle: Math.random() * 10 + 85, // between 85 and 95
		  startVelocity: Math.random() * 10 + 20, // between 20 and 30
		  decay: Math.random() * 0.02 + 0.88, // between 0.88 and 0.9
		  gravity: Math.random() * 0.02 + 0.98, // between 0.98 and 1
		  origin: { x: x / window.innerWidth, y: y / window.innerHeight },
		  colors: colorsArray[Math.floor(Math.random() * colorsArray.length)],
	  });
      try {
        await db.collection("Completed").doc(jobNo).set({
          jobNo: jobNo,
          // other data you want to include for the job...
        });
      } catch (error) {
        console.error("Error adding document to Completed collection: ", error);
      }
    } else {
      // Remove from Completed collection in Firestore.
      try {
        await db.collection("Completed").doc(jobNo).delete();
      } catch (error) {
        console.error("Error removing document from Completed collection: ", error);
      }
    }
  }
  
  private checkIfBooked() {
	  let jobNo = this.params.data.data[0];
	  const docRef = db.collection("Booking").doc(jobNo);

	  docRef.onSnapshot((doc) => {
		if (doc.exists) {
		  this.isBooked = true;
		} else {
		  this.isBooked = false;
		}
	  });
  }
  
  async toggleBooked() {
    this.isBooked = !this.isBooked;
    
    let jobNo = this.params.data.data[0];

    if (this.isBooked) {
      // Add to Booking collection in Firestore.
      try {
        await db.collection("Booking").doc(jobNo).set({
          jobNo: jobNo,
          // other data you want to include for the job...
        });
      } catch (error) {
        console.error("Error adding document to Booking collection: ", error);
      }
    } else {
      // Remove from Booking collection in Firestore.
      try {
        await db.collection("Booking").doc(jobNo).delete();
      } catch (error) {
        console.error("Error removing document from Booking collection: ", error);
      }
    }
  }

  private checkIfSnoozed() {
	  let jobNo = this.params.data.data[0];
	  const docRef = db.collection("Snoozed").doc(jobNo);

	  docRef.onSnapshot((doc) => {
		if (doc.exists) {
		  this.isSnoozed = true;
		} else {
		  this.isSnoozed = false;
		}
	  });
  }
  
  
  private checkIfHidden() {
	  let jobNo = this.params.data.data[0];
	  const docRef = db.collection("Hidden").doc(jobNo);

	  docRef.onSnapshot((doc) => {
		if (doc.exists) {
		  this.isHidden = true;
		} else {
		  this.isHidden = false;
		}
	  });
  }

  async toggleEyeCrossed() {
    this.isHidden = !this.isHidden;
    
    let jobNo = this.params.data.data[0]; // Assuming jobNo is in the first index.

    if (this.isHidden) {
      // Add to Hidden collection in Firestore.
      try {
        await db.collection("Hidden").doc(jobNo).set({
          jobNo: jobNo,
          // other data you want to include for the job...
        });
      } catch (error) {
        console.error("Error adding document to Hidden collection: ", error);
      }
    } else {
      // Remove from Hidden collection in Firestore.
      try {
        await db.collection("Hidden").doc(jobNo).delete();
		await this.hideJobs(); 
      } catch (error) {
        console.error("Error removing document from Hidden collection: ", error);
      }
    }
  }
  
  async toggleSnooze() {
    this.isSnoozed = !this.isSnoozed;
    
    let jobNo = this.params.data.data[0]; // Assuming jobNo is in the first index.

    if (this.isSnoozed) {
      // Add to Snoozed collection in Firestore.
      try {
        await db.collection("Snoozed").doc(jobNo).set({
          jobNo: jobNo,
          // other data you want to include for the job...
        });
      } catch (error) {
        console.error("Error adding document to Snoozed collection: ", error);
      }
    } else {
      // Remove from Snoozed collection in Firestore.
      try {
        await db.collection("Snoozed").doc(jobNo).delete();
      } catch (error) {
        console.error("Error removing document from Snoozed collection: ", error);
      }
    }
  }
  
  toggleEyeCrossedClicked() {
    this.isEyeCrossedClicked = !this.isEyeCrossedClicked;
  }


  @Watch('params')
  onParamsChanged() {
    this.checkNote();
  }

  created() {
    this.checkNote();
    this.checkIfSnoozed();
	this.checkIfHidden();
	this.checkIfBooked();
	this.checkIfCompleted();
  }
  
  
  private beforeDestroy() {
	  for (let key in this.unsub) {
		if (this.unsub[key]) {
		  this.unsub[key]();
		}
	  }
  }

  @Watch('params')
  onParamsChanged(newParams, oldParams) {
    if (this.unsub) {
      this.unsub();
    }
    this.checkNote();
  }

  private checkNote() {
	  let jobNo = this.params.data.data[0]; // Assuming jobNo is in the first index.
	  const docRef = db.collection("notes").doc(jobNo);

	  this.unsub = docRef.onSnapshot((doc) => {
		if (doc.exists) {
		  const data = doc.data();
		  if (data && data.notes && data.notes.length > 0) {
			// If the notes array exists and has at least one note object, set hasNote to true
			this.hasNote = true;
		  } else {
			this.hasNote = false;
		  }
		} else {
		  this.hasNote = false;
		}
	  });
  }


}
