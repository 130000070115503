
import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * Display a loading state
 */
@Component({
  name: "SharedLoading",
  components: {},
})
export default class Loading extends Vue {
  /**
   * Optional max value being counted to (max and value work together)
   */
  @Prop({ default: null })
  private max!: number | null;
  /**
   * Optional current value (max and value work together)
   */
  @Prop({ default: null })
  private value!: number | null;
  /**
   * Include a label inside of the loader
   */
  @Prop({ default: null })
  private label!: string | null;
  /**
   * Whether or not to display the loading state.
   */
  @Prop({ type: Boolean, default: false })
  private loading!: boolean;
}
