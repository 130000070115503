import db from "@/setups/Firebase";
import { EventBus } from '@/setups/event-bus';

export class Row {
  public index: number;
  public data: Record<string, any>; // Changed to an object
  public columnSelections: { lat: number, lng: number } | null = null;
  public features: { name: string, id: string, features: google.maps.Data.Feature[] | null }[] = [];


  public get lat() {
    return parseFloat(this.data[this.columnSelections!.lat]);
  }

  public get lng() {
    return parseFloat(this.data[this.columnSelections!.lng]);
  }

  public get id() {
    return this.index.toString();
  }

  constructor(index: number, row: Record<string, any>, columnSelections: { lat: number, lng: number }) { // Updated row type
    if (!columnSelections) {
        throw new Error('columnSelections is undefined');
    }
    this.index = index;
    this.data = row; // No need to convert
    this.columnSelections = columnSelections;
  }
}


export interface ColumnSelections {
  lat: number;
  lng: number;
  priority?: number;
}

export default class UploadedFile {
  public toUpload: boolean;
  public toSaveChanges: boolean;
  public fileName: string;
  public readonly data: Row[];
  public columnSelections: ColumnSelections; // Use the ColumnSelections interface
  public firstRowHeader: boolean;
  public headers: string[];
  public priorityIndex: number | null = null;
  public startDateIndex: number | null = null;

  constructor(jsonData: any, priorityFromFirestore?: number) {
    this.toUpload = jsonData.toUpload || false;
    this.toSaveChanges = jsonData.toSaveChanges || false;
    this.fileName = jsonData.fileName || "";
    this.columnSelections = jsonData.columnSelections || { lat: 0, lng: 0 };
    this.firstRowHeader = jsonData.firstRowHeader || false;
    this.headers = jsonData.data[0];
    this.priorityIndex = jsonData.data[0].indexOf('Priority:');
    this.startDateIndex = jsonData.data[0].indexOf('Start Date:');

    if (Array.isArray(jsonData.data[0])) {
      const headers = jsonData.data[0]; 
      const rowsData = jsonData.data.slice(1).map((row: any) => {
        const rowArray: any[] = [];
        row.forEach((cell: any, index: number) => {
          rowArray[index] = cell;
        });
        return rowArray;
      });

      this.data = rowsData
        .filter((row: Record<string, any>) => row !== undefined) 
        .map((row: Record<string, any>, index: number) => {
          return new Row(index, row, this.columnSelections)
      });
    } else {
      this.data = jsonData.data.map((rowData: any) => {
        const row = new Row(rowData.index, rowData.data, rowData.columnSelections);
        row.features = rowData.features;
        return row;
      });
    }

    this.getFirestorePriority().then(priorityFromFirestore => {
      // Update priority in each Row.data
      this.data.forEach(row => {
        if (Array.isArray(row.data) && this.priorityIndex !== null) {
          const priorityFromJson = row.data[this.priorityIndex];

          // If priority from JSON data is 4586, use priority from Firestore
          if (priorityFromJson === 4586 && priorityFromFirestore !== undefined) {
            this.columnSelections.priority = priorityFromFirestore;
            row.data[this.priorityIndex] = priorityFromFirestore;
            EventBus.$emit('priority-updated', this.columnSelections.priority);
          }
        }
      });
    });
  }

  private async getFirestorePriority() {
    const defaultPriorityDoc = await db.collection("Priority").doc("Priority").get();
    let defaultPriority = null;

    if (defaultPriorityDoc.exists) {
      const data = defaultPriorityDoc.data();
      if (data) {
        defaultPriority = data.defaultPriority;
      }
    }

    return defaultPriority;
  }

  public toJSON() {
    return {
      toUpload: this.toUpload,
      toSaveChanges: this.toSaveChanges,
      fileName: this.fileName,
      data: this.data.map(row => ({
        index: row.index,
        data: row.data,
        columnSelections: row.columnSelections,
        features: row.features,
      })),
      columnSelections: this.columnSelections,
      firstRowHeader: this.firstRowHeader,
      headers: this.headers,
      priorityIndex: this.priorityIndex,
      startDateIndex: this.startDateIndex,
    };
  }
}

