import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUFZk7Zt2EUx5NEx5ndw2ovha43nmLqYM",
  authDomain: "nec-smallworks-planner.firebaseapp.com",
  projectId: "nec-smallworks-planner",
  storageBucket: "nec-smallworks-planner.appspot.com",
  messagingSenderId: "718137094070",
  appId: "1:718137094070:web:2e57dc363864a95930db17",
  measurementId: "G-LJZB6M8MS4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firestore
const db = firebase.firestore();


export default db;
