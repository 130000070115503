import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import createPersistedState from 'vuex-persistedstate';  // import the plugin
import UploadedFile, { Row, ColumnSelections } from "@/entities/UploadedFile"; // Assuming this is the path to your UploadedFile class

Vue.use(Vuex);

// Define our state type.
interface State {
  uploadedFile: UploadedFile | null;
}

// Create our store options.
const store: StoreOptions<State> = {
  state: {
    uploadedFile: null,
  },
  mutations: {
    setUploadedFile(state, file: any) {
      state.uploadedFile = new UploadedFile(file);
    }
  },
  getters: {
    uploadedFile: state => state.uploadedFile
  },
  plugins: [
    createPersistedState({
      reducer: (state) => ({
        uploadedFile: {
          toUpload: state.uploadedFile?.toUpload,
          toSaveChanges: state.uploadedFile?.toSaveChanges,
          fileName: state.uploadedFile?.fileName,
          data: state.uploadedFile?.data.map((row: Row) => ({
            index: row.index,
            data: row.data,
            columnSelections: row.columnSelections,
            features: row.features,
          })),
          columnSelections: state.uploadedFile?.columnSelections as ColumnSelections,
          firstRowHeader: state.uploadedFile?.firstRowHeader,
          headers: state.uploadedFile?.headers,
          priorityIndex: state.uploadedFile?.priorityIndex,
          startDateIndex: state.uploadedFile?.startDateIndex,
        },
      }),
    }),
  ],
};

export default new Vuex.Store<State>(store);
