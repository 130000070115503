<template>
  <div
    v-if="showModal"
    class="modal-overlay"
  >
    <div class="white-box">
      <!-- Add your content here -->
      <button @click="showModal = false">
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhiteBox',
  data() {
    return {
      showModal: false
    };
  }
}
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-box {
  width: 50%;
  height: 50%;
  background-color: white;
  padding: 20px;
}
</style>
