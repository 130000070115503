
import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase/compat/app";
import db from '@/setups/Firebase';

@Component
export default class SignIn extends Vue {
  fullName = "";
  email = "";
  password = "";
  errorMessage = "";

   async mounted() {
		const user = firebase.auth().currentUser;
		if (user) {
		  const doc = await db.collection('users').doc(user.uid).get();
		  const userData = doc.data();

		  if (userData && userData.verified) {
			// User is signed in and verified, redirect to Explore page
			this.$router.push({ name: 'Explore' });
		  } else {
			// User is signed in but not verified, redirect to PendingApproval page
			this.$router.push({ name: 'PendingApproval' });
		  }
		 }
	}



	async signUp() {
	// Check if full name is provided
	if (!this.fullName) {
	this.errorMessage = "Please enter your full name.";
	return;
	}

	try {
	const userCredential = await firebase.auth().createUserWithEmailAndPassword(this.email, this.password);
	const user = userCredential.user;

	if (user) {
	// Store user data in Firestore
	const userData = {
	email: this.email,
	fullName: this.fullName,
	verified: false,
	admin: false,
	};

	await db.collection('users').doc(user.uid).set(userData);

	// Send email to admin as before...
	}

	this.$router.push({ name: "PendingApproval" });  // Redirect to PendingApproval page after successful sign up
	} catch (error) {
	this.errorMessage = error.message;  // Display any error message that comes from Firebase
	}
	}


	async signIn() {
	try {
	const userCredential = await firebase.auth().signInWithEmailAndPassword(this.email, this.password);
	const user = userCredential.user;

	if (user) {
	const doc = await db.collection('users').doc(user.uid).get();
	const userData = doc.data();

	if (userData && userData.verified) {
	// User is signed in and verified.
	if (this.$router.currentRoute.name !== 'Explore') {
	this.$router.push({ name: "Explore" });  // Redirect to Explore page after successful login
	}
	} else {
	// User is signed in but not verified.
	if (this.$router.currentRoute.name !== 'PendingApproval') {
	this.$router.push({ name: "PendingApproval" });  // Redirect to PendingApproval page
	}
	}
	}
	} catch (error) {
	this.errorMessage = error.message;  // Display any error message that comes from Firebase
	}
	}






	}
