
import { Component, Vue, Prop } from "vue-property-decorator";

/**
 * On upload upsell modal
 */
@Component({
  name: "PricingUpsellOnUpload",
  components: {
    Tiers: () => import("@/components/Pricing/Tiers/Tiers.vue"),
  },
})
export default class OnUpload extends Vue {
  @Prop({ default: "Upgrade to Pro for unlimited rows and uploads" })
  private headline!: string

  private visibleAux: boolean = true;
  private get visible() {
    return this.visibleAux;
  }
  private set visible(newValue: boolean) {
    if (!newValue) {
      /**
       * Close the upsell modal
       */
      this.$emit("close");
    }
    this.visibleAux = newValue;
  }
}
