
	import { Component } from "vue-property-decorator";
	import UploadedFile, { Row } from "@/entities/UploadedFile";
	import { updateUploadedFile } from "@/store/exploreStore";
	import _View from "./_View";
	import exampleApi from "@/api/example";
	import ExampleTeaserI from "@/entities/ExampleTeaser";
	import Loading from "@/components/Shared/Loading/Loading.vue";
	import { RouteNames } from '@/router/index';
	import jsonData from '@/public/jobDataWithCoordinates.json';
	import db from '@/setups/Firebase';

	@Component({
	name: "ViewsHome",
	components: {
	Loading,
	},
	})
	export default class Home extends _View {
	private loading: boolean = false;
	private currentTime: string = '';

	private getCurrentDateTime(): string {
	const currentDate = new Date();
	const dateString = currentDate.toLocaleDateString('en-GB', {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	});
	return dateString;
	}

	private startClock() {
	setInterval(() => {
	const currentDateTime = this.getCurrentDateTime();
	this.currentTime = currentDateTime;
	if (this.uploadedFile) {
	this.uploadedFile.fileName = currentDateTime;
	}
	}, 1000);
	}

	private uploadedFile: UploadedFile | null = null;

	private async loadFirestoreData() {
	  this.loading = true;
	  try {
		const jobsRef = db.collection('Jobs');
		const snapshot = await jobsRef.get();
		const firestoreData = snapshot.docs.map(doc => doc.data());
	

		const headers = ['Job No.', 'Description', 'address', 'Client', 'Start Date:', 'Priority:', 'latitude', 'longitude'];
		const dataAsArray = firestoreData.map((job) => {
		  const dateObj = new Date(job['Start Date'].seconds * 1000);
		  const day = String(dateObj.getDate()).padStart(2, '0');
		  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
		  const year = dateObj.getFullYear();

		  const lat = parseFloat(job.Latitude);
		  const lng = parseFloat(job.Longitude);

		  return [
			job['Job No.'],
			job.Description,
			job.Address,
			job.Client,
			`${day}/${month}/${year}`,
			job['Priority'] ?? null,
			lat,
			lng
		  ];
		});

		dataAsArray.unshift(headers);
		this.uploadedFile = new UploadedFile({
		  toUpload: false,
		  toSaveChanges: false,
		  fileName: this.getCurrentDateTime(),
		  data: dataAsArray,
		  columnSelections: { lat: 6, lng: 7, priority: 5 },
		  firstRowHeader: false,
		});
		updateUploadedFile(this.uploadedFile);
	

		
	  } catch (error) {
		console.error('Error loading Firestore data:', error);
	  } finally {
		this.loading = false;
	  }
	}




	protected created() {  // Changed from 'activated' to 'created'
	this.startClock();
	this.loadFirestoreData();
	console.log("created method has been called");  // Debug log
	}
	}

