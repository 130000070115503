
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import NavigationDrawer from "./NavigationDrawer.vue";
import Login from "./Login/Login.vue";
import state from "@/store/exploreStore";
import driveState, { renameFile, updateShared } from "@/store/driveStore";
import {
  mdiMenuDown,
  mdiLink,
  mdiCloudCheckOutline,
  mdiAutorenew,
} from "@mdi/js";
import DoubleClickEditText from "@/components/Shared/DoubleClickEditText/DoubleClickEditText.vue";

/**
 * Navigation Bar at the top of the website to navigate between sections
 */
@Component({
  name: "NavBar",
  components: {
    Login,
    NavigationDrawer,
    DoubleClickEditText,
  },
})
export default class NavBar extends Vue {
  /**
   * Whether or not to display the drawer
   */
  @Prop({ type: Boolean, default: false })
  private drawerAllowed!: boolean;
  private drawer: boolean | null = null;
  private mdiMenuDown = mdiMenuDown;
  private mdiLink = mdiLink;
  private mdiCloudCheckOutline = mdiCloudCheckOutline;
  private mdiAutorenew = mdiAutorenew;

  private get loggedIn() {
    return driveState.user !== null;
  }

  private get savesAllowed() {
    if (state.uploadedFile) {
      return state.uploadedFile.toSaveChanges;
    }
    return false;
  }

  private get driveFolderUrl() {
    return `https://drive.google.com/drive/folders/${driveState.folderId}`;
  }

  private get isSaving() {
    return (
      state.saving.file || state.saving.configFile || state.saving.geojsonFile
    );
  }

  private get isSaved() {
    if (state.uploadedFile) {
      return (
        driveState.files.filter((_) => _.name === state.uploadedFile!.fileName)
          .length > 0
      );
    }
    return false;
  }

  private get fileName() {
    if (state.uploadedFile) {
      if (state.uploadedFile.fileName.indexOf(".") > -1) {
        return state.uploadedFile.fileName.split(".").slice(0, -2).join(".");
      }
      return state.uploadedFile.fileName;
    }
    return "";
  }

  private set fileName(newValue: string) {
    const rename = state.uploadedFile!.fileName.replace(
      this.fileName,
      newValue
    );
    const file = driveState.files.find(
      (file) => file.name === state.uploadedFile!.fileName
    );
    if (file) {
      const configFile = driveState.files.find(
        (_) => _.name === `${file.name}.json`
      );
      const geojsonFile = driveState.files.find(
        (_) => _.name === `${file.name}.geojson.json`
      );
      if (configFile) {
        const configFileName = configFile.name!.replace(file.name!, rename);
        renameFile(configFile.id!, configFileName);
      }
      if (geojsonFile) {
        const geojsonFileName = geojsonFile.name!.replace(file.name!, rename);
        renameFile(geojsonFile.id!, geojsonFileName);
      }
      const fileName = file.name!.replace(file.name!, rename);
      renameFile(file.id!, fileName);
    }
    state.uploadedFile!.fileName = state.uploadedFile!.fileName.replace(
      state.uploadedFile!.fileName,
      rename
    );
  }

  private get fileWebViewLink() {
    if (state.uploadedFile) {
      const file = driveState.files.find(
        (file) => file.name === state.uploadedFile!.fileName
      );
      if (file) {
        return file.webViewLink;
      }
    }
    return null;
  }

  @Watch("drawerAllowed")
  private drawerAllowedUpdated() {
    if (!this.drawerAllowed) {
      this.drawer = null;
    }
  }

  private toggleDrawer() {
    this.drawer = !this.drawer;
  }

  private updateShared() {
    const files = [
      driveState.files.find((_) => _.name === state.uploadedFile!.fileName),
      driveState.files.find(
        (_) => _.name === `${state.uploadedFile!.fileName}.json`
      ),
      driveState.files.find(
        (_) => _.name === `${state.uploadedFile!.fileName}.geojson.json`
      ),
    ];
    const filteredFiles = files.filter((_) => _) as gapi.client.drive.File[];
    if (filteredFiles.length) {
      const currentShared = filteredFiles[0].shared!;
      updateShared(filteredFiles, !currentShared);
    }
  }
}
