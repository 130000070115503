
	import { Component, Vue } from 'vue-property-decorator';
	import { IFilterParams, IDoesFilterPassParams, IFilterComp } from '@ag-grid-community/core';
	import db from '@/setups/Firebase';
	import { Watch } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { EventBus } from '@/setups/event-bus';

	@Component({
	})
	export default class StatusFilter extends Vue implements IFilterComp {
	private selectedStatus: string = '';
	private params!: IFilterParams;
	private jobStatusMap: { [key: string]: Set<string> } = Vue.observable({});
  private selectedStatus: string[] = [];
  private statuses: string[] = ["completed", "snoozed", "booked", "hidden", "notes", "Misc"];


  created() {
	  this.selectedStatus = [...this.statuses];
	  this.fetchJobStatuses();

  EventBus.$on('clear-filters', () => { // Listen for event
		this.clearFilter();
	  });
  }

	// Method to clear the filter
  clearFilter() {
	  this.selectedStatus = [...this.statuses];
	  this.params.filterChangedCallback();
	  this.params.api.onFilterChanged();// Notify ag-Grid of filter change
  }

  isOpen: boolean = false;

  openCloseDropdown() {
    this.isOpen = !this.isOpen;
  }

  // Helper method to capitalize a string
  private capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public getFrameworkComponentInstance() {
    return this;
  }

  public getGui() {
    return this.$el;
  }
  
  

  private getFirestoreCollectionName(filterName: string) {
    const map = {
      'booked': 'booking',
      // add other mappings as needed
    };
    return map[filterName] || filterName;
  }

  public doesFilterPass(params: IDoesFilterPassParams) {
	  const jobNumber = params.node.data.data[0];

	  if (this.jobStatusMap[jobNumber]) {
		const jobStatusesArray = Array.from(this.jobStatusMap[jobNumber]);
		const jobStatusesLower = jobStatusesArray.map(s => s.toLowerCase());
		const selectedStatusesLower = this.selectedStatus.map(s => this.getFirestoreCollectionName(s).toLowerCase());

		// If there are no selected statuses, return false
		if (this.selectedStatus.length === 0) {
		  return false;
		}

		// If 'Misc' is the only selected filter, return true only if none of the job's statuses match any filter
		if (this.selectedStatus.length === 1 && this.selectedStatus.includes("Misc")) {
		  return this.statuses.every(status => !jobStatusesLower.includes(this.getFirestoreCollectionName(status).toLowerCase()));
		}

		// If any of the job's statuses match a deselected status, return false
		for (const status of jobStatusesLower) {
		  if (!selectedStatusesLower.includes(status)) {
			return false;
		  }
		}

		// If none of the job's statuses match a deselected status, return true
		return true;
	  } else {
		// If the job does not have any statuses, return true only if 'Misc' is selected
		return this.selectedStatus.includes("Misc");
	  }
  }







  public isFilterActive() {
    // The filter is active if the selected status is not an empty string
    return this.selectedStatus !== '';
  }

  public afterGuiAttached(params?: IAfterGuiAttachedParams): void {
    if (this.parentComponent) {
      this.parentComponent.setModel(this.model);
    }
  }

  public onStatusChanged() {
    this.params.filterChangedCallback();
  }

  public getModel() {
    // Return the current filter model
    return {selectedStatus: this.selectedStatus};
  }

  public setModel(model: any) {
    // Set the current filter model
    this.selectedStatus = model.selectedStatus || [];
  }

  private async fetchJobStatuses() {
    const statusCollections = ['Booking', 'Completed', 'Hidden', 'Snoozed', 'notes'];
    for (const status of statusCollections) {
      try {
        db.collection(status).onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const jobNumber = change.doc.id;

            // Initialize if necessary
            if (!this.jobStatusMap[jobNumber]) {
              this.jobStatusMap[jobNumber] = new Set();
            }

            // Add or remove from the set based on the type of the change
            if (change.type === "added" || change.type === "modified") {
              this.jobStatusMap[jobNumber].add(this.getFirestoreCollectionName(status).toLowerCase());
            } else if (change.type === "removed") {
              this.jobStatusMap[jobNumber].delete(this.getFirestoreCollectionName(status).toLowerCase());
            }
          });
        });
      } catch (error) {
        console.error("Error fetching job statuses: ", error);
      }
    }
  }
}
